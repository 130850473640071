<template>
  <div class='userInfo'>
    <van-form @submit="onSubmit">
      <div v-for="(item, index) in userFormData" :key="index">
        <!-- 出生地选择 -->
        <van-field v-if="item.type === 'region'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" @click="showArea = true" />
        <!-- 性别选择 -->
        <van-field v-else-if="item.type === 'sex'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" @click="showPickerSex = true" />
        <!-- 现居地选择 -->
        <van-field v-else-if="item.type === 'residence'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" @click="showResidence = true" />
        <!-- 选择出生年月 -->
        <van-field v-else-if="item.type === 'time'" readonly clickable :name="item.name" :value="item.value"
          :label="item.label" :placeholder="item.placeholder" @click="showPicker = true" />
          <!-- 身高输入框 -->
          <van-field v-else-if="item.label=='身高'" v-model="item.value" :name="item.name" :label="item.label"
            :placeholder="item.placeholder" :rules="item.rules" >
            <van-button style="border: none;height: auto;position: relative;top: -3px;" slot="button">cm</van-button>
            </van-field>
            <van-field v-else-if="item.label=='体重'" v-model="item.value" :name="item.name" :label="item.label"
              :placeholder="item.placeholder" :rules="item.rules" >
              <van-button style="border: none;height: auto;position: relative;top: -3px;" slot="button">kg</van-button>
              </van-field>
        <!-- 普通输入框 -->
        <van-field v-else :type="item.type" v-model="item.value" :name="item.name" :label="item.label"
          :placeholder="item.placeholder" :rules="item.rules" />
        <!-- 性别选择 -->
        <van-popup v-model="showPickerSex" position="bottom">
          <van-picker show-toolbar :columns="columns" @confirm="onConfirmSex" @cancel="showPickerSex = false" />
        </van-popup>
        <!-- 出生地选择 -->
        <van-popup v-model="showArea" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
        </van-popup>
        <!-- 现居地选择 -->
        <van-popup v-model="showResidence" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirmResidence" @cancel="showResidence = false" />
        </van-popup>
        <!-- 时间选择器 -->
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :columns-order="['year', 'month', 'day']"
            :max-date="maxDate" :min-date="minDate" @confirm="onConfirmTime" @cancel="showPicker = false" />
        </van-popup>
      </div>
      <div class="submitBtn">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
</div>
</template>
<script>

import { CompleteUserInfo, GetUserInfo } from '@/api/index'
import { areaList } from '@vant/area-data'
import { mapGetters } from 'vuex'
import wx from '@/util/jweixin1.3.2'
import { setTimeout } from 'timers'

export default {
  components: {

  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  },
  data () {
    return {
        ifFast:false,
        show:true,
        pattern: /^[a-zA-Z\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/,
      userFormData: [
        {
          name: 'userName',
          label: '姓名',
          placeholder: '姓名',
          rules: [{ required: true, message: '请填写姓名' }],
          value: ''
        },
        {
          name: 'sex',
          label: '性别',
          placeholder: '性别',
          rules: [{ required: true, message: '请填写身份证上性别' }],
          type: 'sex',
          value: ''
        },
        {
          name: 'birthDay',
          label: '出生日期',
          placeholder: '出生日期',
          type: 'time',
          rules: [{ required: true, message: '请选择出生日期' }],
          value: ''
        },
        {
          name: 'birthplace',
          label: '出生地',
          placeholder: '出生地',
          rules: [{ required: true, message: '请选择出生地' }],
          type: 'region',
          value: ''
        },
        {
          name: 'residence',
          label: '现居地',
          placeholder: '现居地',
          rules: [{ required: true, message: '请填写现居地' }],
          type: 'residence',
          value: ''
        },
        {
          name: 'height',
          label: '身高',
          placeholder: '身高',
          rules: [{ required: true, message: '请填写身高' }],
          value: ''
        },
        {
          name: 'weight',
          label: '体重',
          placeholder: '体重',
          rules: [{ required: true, message: '请填写体重' }],
          value: ''
        }
      ],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      areaList, // 地址选择数据
      form: {},
      showArea: false,
      showResidence: false,
      showPicker: false,
      currentDate: new Date(),
      showPickerSex: false,
      columns: ['男', '女']
    }
  },
  props: {

  },
  created () {
    const openid = this.$query('openid')
    const token = this.$query('token')
    const userid = this.$query('userid')
    token && this.$store.commit('SET_TOKEN', token)
    openid && this.$store.commit('SET_OPENID', openid)
    userid && this.$store.commit('SET_USERID', userid)
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
      confirm(e){
        console.log('确定')  
      },
      cancel(e){
        console.log('取消')  
      },
    // 获取用户信息
    getUserInfo () {
      var that = this
      this.$toast.loading({
        message: '获取信息中'
      })
      // alert("this.userid",this.userid)
      // alert(this.userid)
      GetUserInfo({ userid: this.userid }).then(res => {
        // GetUserInfo({ userid: 'Mini_067bdf0e-a32a-47c7-83e9-11088dc08d49' }).then(res => {
        const { data } = res
        if (data) {
            if(data.updataTime){
                that.ifFast= data.updataTime;
            }
          this.userFormData.forEach(item => {
            console.log(item)
            if (data[item.name]) {
              item.value = data[item.name]
            }
            if (item.name === 'sex') {
              console.log(data[item.name])
              item.value = data[item.name] == '1' ? '男' : data[item.name] == '2' ? '女' : '未知'
            }
            if (item.name === 'birthDay') {
              console.log(data, '+++')
              item.value = new Date(data.birthday).toLocaleDateString().replace(/\//g, '-')
            }
            if (item.name === 'birthplace') {
              item.value = data.Birthplace
            }
            if (item.name === 'residence') {
              item.value = data.Residence
            }
            if (item.name === 'height') {
              item.value = data.Height
            }
            if (item.name === 'weight') {
              item.value = data.Weight
            }
          })

          // console.log(data.birthplace,"+++++++")//出生地
          // this.form.birthplace = data.birthplace

          // setTimeout(() => {
          //   // this.form.birthplace
          //   this.form.birthplace == data.Birthplace
          // }, 2000);

          // this.form.city = data.city
          // this.form.area = data.area
        } else {
          // this.$toast.fail('信息获取失败')
        }
        // this.$toast.clear()
      })
    },

    // 出生地选择
    onConfirm (values) {
      const birthplace = this.userFormData.find(el => el.type === 'region')
      birthplace.value = `${values[0].name}-${values[1].name}-${values[2].name}`
      this.form.provice = values[0].name
      this.form.city = values[1].name
      this.form.area = values[2].name
      this.form.birthplace = birthplace.value
      console.log(this.form.birthplace, '++++')
      this.showArea = false
    },
    // 现居地选择
    onConfirmResidence (values) {
      const residence = this.userFormData.find(el => el.type === 'residence')
      residence.value = `${values[0].name}-${values[1].name}-${values[2].name}`
      this.form.residence = residence.value
      this.showResidence = false
    },
    // 性别选择
    onConfirmSex (values) {
      console.log(values)
      const residence = this.userFormData.find(el => el.type === 'sex')
      console.log(residence.value)
      residence.value = values
      this.showPickerSex = false
    },
    // 时间选择
    onConfirmTime (values) {
      const nowdata = new Date(values)
      const year = nowdata.getFullYear()
      const month = nowdata.getMonth() + 1
      const date = nowdata.getDate()
      let clock = year + '-'
      if (month < 10) {
        clock += '0'
      };
      clock += month + '-'
      if (date < 10) {
        clock += '0'
      };
      clock += date
      const birthDay = this.userFormData.find(el => el.type === 'time')
      birthDay.value = clock
      this.form.age = new Date().getFullYear() - year
      this.showPicker = false
    },
    // 提交
    onSubmit(values) {
      var that = this
      this.userFormData.forEach(item => {
        this.form[item.name] = item.value
        if (item.name === 'sex') {
          if (item.value === '男') {
            this.form.sex = 1;
          } else if (item.value === '女') {
            this.form.sex = 2;
          } else {
            this.form.sex = 3;
          }
        }
      })
      console.log('this.userFormData[0].value', this.userFormData[0].value)
      if (this.userFormData[0].value == '会员用户'||this.userFormData[0].value == '会员') {
        this.$toast.fail('请填写身份证上的姓名')
        return
      } else if (!this.pattern.test(this.userFormData[0].value)) {
        console.log("999")
        that.$toast.fail('请填写身份证上的姓名')
        return
      } else {
        this.form.userid = 'Mini_df80d757-6677-4cfe-8df7-e458e7684204'
        that.updataUserInfo(this.form)
      }
      this.form.userid = this.userid

      console.log(this.form)
      return
      this.updataUserInfo(this.form)
    },
    updataUserInfo (data) {
        let that = this;
      console.log(data, 'data')
      // return
      CompleteUserInfo(data).then(res => {
        // const {status} = res;
        if (res) {
          this.$toast.success('修改成功')
          console.log('that.ifFast',that.ifFast)
          if(that.ifFast){
              wx.miniProgram.reLaunch({
                    url: '/pages/index/index'
                  })
          }else{
              this.$dialog.confirm({
                title: '提示',
                confirmButtonText: '确定',
                message: '请连接蓝牙'
              }).then(() => {
                wx.miniProgram.reLaunch({
                  url: '/pages/newBlueTooth/index'
                })
              }).catch(() => {
                  wx.miniProgram.reLaunch({
                    url: '/pages/index/index'
                  })
              })
          }
         
        }
      })
    }
  },
  watch: {

  }
}
</script>
<style lang='scss' scoped>
.userInfo {
  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, .2);
  }

  .submitBtn {
    margin-top: 40px;
    padding: 0 40px;
  }
}
.van-button--info{
  background:#9966ff !important;
  border: 1px solid #9966ff;
}

</style>
